import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import router from 'next/dist/client/router';
import Link from 'next/link';
import { useDisconnect } from 'wagmi';

const menuItemsInitialVale = [
  {
    title: 'About',
    link: 'https://about.fileverse.io/',
    target: '_blank',
  },
  {
    title: 'My Personal Files',
    link: '/files',
  },
  {
    title: 'Share New File',
    link: '/',
  },
  {
    title: 'My Access NFTs',
    link: '/profile',
  },
];

const AccountTab = () => {
  const { disconnect } = useDisconnect();
  const handleDisconnectWallet = () => {
    disconnect();
    localStorage.removeItem('token');
    if (!(window && window.location.pathname.includes('files/')))
      router.push('/');
    else router.reload();
  };

  const [menuItems, setMenuItems] = useState(menuItemsInitialVale);
  const isMedia640px = useMediaQuery('(max-width : 640px)');

  useEffect(() => {
    if (isMedia640px) {
      const mItems = [...menuItems];
      mItems.splice(1, 0, { title: 'Why Fileverse', link: '/why-fileverse' });
      setMenuItems(mItems);
    }
  }, [isMedia640px]);

  return (
    <div
      className={clsx(
        'flex flex-col absolute top z-10 bg-white py-2  border-fade border rounded-lg shadow-lg'
      )}
    >
      {menuItems.map((item) => (
        <Link href={item.link} key={item.title} passHref>
          <a target={item.target}>
            <div className="h-[34px] flex items-center mx-[12px] px-2 py-2 border-fade border-bottom border-b">
              <span className="hover:cursor-pointer">{item.title}</span>
            </div>
          </a>
        </Link>
      ))}
      <div
        className="h-[34px] flex items-center  mx-[12px] px-2 py-2 mb-2"
        onClick={() => {
          handleDisconnectWallet();
        }}
      >
        <span className="hover:cursor-pointer">{'Disconnect'}</span>
      </div>
    </div>
  );
};

export default AccountTab;
