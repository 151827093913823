/* eslint-disable no-console */
import { useMutation } from 'react-query';

import { ILoginRequest } from 'interfaces/ApiType/apiType';

import { publicApi, queryClient } from './index';

export const loginAPI = async ({
  address,
  signature,
  message,
}: ILoginRequest) => {
  // @ts-ignore
  const response = await publicApi.post(`/account/${address}/login`, {
    message,
    signature,
  });
  return response;
};

export const useLogin = () => {
  return useMutation<any, unknown, ILoginRequest>('login', loginAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
