import React, { useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { hotjar } from 'react-hotjar';

import HeadSection from './HeadSection';

const Wrapper = ({ children }: any) => {
  const isMedia920px = useMediaQuery('(max-width : 920px)');
  useEffect(
    () =>
      hotjar.initialize(
        Number(process.env.HOTJAR_SITE_ID),
        Number(process.env.HOTJAR_NUMBER)
      ),
    []
  );
  return (
    <div className="h-[100vh] bg-yellow min-h-fit ">
      <div
        className={`h-[10vh] flex items-center ${
          isMedia920px ? 'px-3 py-4' : 'px-6 py-2'
        }`}
      >
        <HeadSection />
      </div>

      <div
        className={` flex items-center w-full h-[85vh] overflow-auto ${
          isMedia920px ? 'px-3 py-4' : 'px-6 py-2'
        }`}
      >
        {children}
      </div>
      <footer className="fixed h-fit p-2 inset-x-0 bottom-0 border-t border-black flex bg-yellow justify-center">
        <div
          className="flex text-center justify-center items-center text-[14px]"
          style={{ fontWeight: '400' }}
        >
          <strong>
            Fileverse is a trustless collaboration dApp supported by 14,000+
            Gitcoin & Ethereum donors as well as leading protocols, investors &
            angels ❤️
          </strong>
        </div>
      </footer>
    </div>
  );
};

export default Wrapper;
