import jwtDecode from 'jwt-decode';

interface JWT {
  address: string;
  exp: number;
}

export const isTokenValid = (token: string | null, address: string) => {
  if (!token) return false;

  const jwt: JWT = jwtDecode(token);

  if (jwt.address?.toLowerCase() !== address?.toLowerCase()) {
    localStorage.removeItem('token');
    return false;
  }

  if (jwt.exp) {
    const isValid = new Date().valueOf() < jwt.exp * 1000;
    if (!isValid) {
      localStorage.removeItem('token');
    }

    return isValid;
  }

  localStorage.removeItem('token');
  return false;
};

export const getFilesFromLocalstorage = (FILES_KEY: string) => {
  const filesData = localStorage.getItem(FILES_KEY);
  const filesInLS = filesData ? JSON.parse(filesData) : [];

  if (Array.isArray(filesInLS)) {
    return filesInLS;
  }

  return [];
};
