/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import router from 'next/dist/client/router';
import Link from 'next/link';
import { SiweMessage } from 'siwe';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';

import { loginAPI } from '@/api/login';
import useComponentVisible from '@/hooks/useComponentVisible';
import useMediaQuery from '@/hooks/useMediaQuery';
import { sendNotifcation } from '@/utils/notification';
import { useEagerConnect } from '@/utils/useEagerConnect';
import BurgerLogo from 'public/assets/burger.svg';
import newTabIcon from 'public/assets/gotoIcon.svg';
import Logo from 'public/assets/mainLogo.png';

import { isTokenValid } from '../../utils';
import { Spinner } from '../Spinner/Spinner';
import AccountTab from './AccountTab';

const HeadSection = ({
  disableHeaderWalletLogin,
}: {
  disableHeaderWalletLogin?: boolean;
}) => {
  const isMedia640px = useMediaQuery('(max-width : 640px)');
  const isMedia920px = useMediaQuery('(max-width : 920px)');
  const [userLogin, setUserLogin] = useState(false);
  const { data: signer } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { disconnect } = useDisconnect();
  const [isConnecting, setConnecting] = useState(false);
  const handleConnect = async () => {
    try {
      setUserLogin(false);
      const token = localStorage.getItem('token');
      if (
        !isTokenValid(token, signer?.address) &&
        signer?.address &&
        !isConnecting
      ) {
        setConnecting(true);
        const message = new SiweMessage({
          domain: window.location.host,
          address: signer?.address,
          statement: `Welcome to Fileverse\nTimestamp: ${new Date().toISOString()}`,
          uri: window.location.origin,
          version: '1',
        });
        const signature = await signMessageAsync({
          message: message.prepareMessage(),
        });
        const result = await loginAPI({
          address: signer?.address,
          signature,
          message: message.prepareMessage(),
        });
        if (result) {
          const accessToken = result?.data?.token;
          localStorage.setItem('token', accessToken);
          localStorage.setItem('address', signer?.address);

          // router.reload();
          setUserLogin(true);
        }
        setConnecting(false);
      }
      return;
    } catch (error) {
      setConnecting(false);
      disconnect();
      sendNotifcation('Wallet Disconnected', '', 'danger');
    }
  };

  useEagerConnect();
  useEffect(() => {
    if (!disableHeaderWalletLogin) {
      handleConnect();
    }
  }, [signer?.address]);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className={`flex w-full `} ref={ref}>
      <div
        className="flex w-[50%] justify-items-start items-center cursor-pointer"
        onClick={async () => {
          router.push('/');
        }}
      >
        <img src={Logo.src} alt="Fileverse" className={`h-[2.5rem] mr-2`} />

        <span
          className={` leading-3 ${
            isMedia640px ? 'text-[0.75rem]' : 'text-default'
          }  font-xx`}
        >
          Fileverse
        </span>
      </div>
      <div className="flex w-[50%] flex-wrap items-center relative mx-auto md:mr-0 justify-end">
        {isComponentVisible && <AccountTab />}
        {!isMedia640px && (
          <Link href={'https://fileverse.io/portal '} passHref={true}>
            <a
              target={'_blank'}
              className="border-2 flex items-center border-black mr-4 px-4 rounded-md py-1"
            >
              <h1 className="font-semibold">Discover Portal</h1>
              <img
                className="ml-2 w-4"
                src={newTabIcon.src}
                alt="new tab icon"
              />
            </a>
          </Link>
        )}

        {(typeof window !== 'undefined' &&
          isTokenValid(localStorage.getItem('token'), signer?.address)) ||
        userLogin ? (
          <div>
            {isMedia640px && (
              <img
                src={BurgerLogo.src}
                alt="Menu"
                onClick={() => {
                  setIsComponentVisible(!isComponentVisible);
                }}
                className="mr-2 h-6"
              />
            )}
            <button
              onClick={async () => {
                setIsComponentVisible(!isComponentVisible);
              }}
              className={`bg-black text-white rounded-lg py-2 text-lg ${
                isMedia920px ? 'scale-[90%] px-6' : 'px-10'
              } ${isMedia640px ? 'hidden' : ''}`}
            >
              Account
            </button>
          </div>
        ) : (
          <ConnectButton.Custom>
            {() => {
              return (
                <div className="flex items-center md:mr-0 justify-end">
                  {(() => {
                    const connecting =
                      signer?.address &&
                      typeof window !== 'undefined' &&
                      !isTokenValid(
                        localStorage.getItem('token'),
                        signer?.address
                      );

                    return (
                      <Link
                        href={'https://about.fileverse.io/'}
                        passHref={true}
                      >
                        <button
                          type="button"
                          className={`bg-black text-white rounded-lg py-2 text-lg ${
                            isMedia640px ? 'px-3 text-sm' : 'px-10'
                          }`}
                        >
                          {connecting ? <Spinner /> : 'About'}
                        </button>
                      </Link>
                    );
                  })()}
                </div>
              );
            }}
          </ConnectButton.Custom>
        )}
      </div>
    </div>
  );
};

export default HeadSection;
