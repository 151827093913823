import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';

export function sendNotifcation(
  title: String,
  message: String,
  type: NOTIFICATION_TYPE
) {
  Store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'bottom-left',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}
